@import './styles/colors.scss';
@import './styles/fonts.scss';
@import './styles/measurements.scss';

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  margin: 0;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  .navbar {
    display: flex;
    flex-direction: row;
    height: 5vh;
    width: 100vw;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 2;

    .nav {
      height: 100%;
      width: 100vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      list-style-type: none;
      background:rgba(255, 255, 255, 0.4);
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);

      
      li {
        font-size: 20px;
        
        a {
          color: $dark-grey;
          text-decoration: none;
          transition: border-bottom .2s;
          &:hover {
            border-bottom: 1px solid $dark-grey;
          }
        }
      }
    }
  }
}

body {
  margin: 0;
}

.splash-container {
  width: 100vw;
  background: url('./images/big-lemon-background.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-container {
    background: rgba(255, 255, 255, 0.4);
    color: white;
    border-radius: 5px;
    box-shadow: 0px 10px 15px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin: 20px;
    }

    h1 {
      font-weight: normal;
      color: $dark-grey;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $light-grey;
}

.about-container {
  .section-content {

    .about-image {
      background-image: url('../src/images/self-black.jpeg');
      background-size: cover;
      border-radius: 50%;
    }

    .about-btns {

      button {
        cursor: pointer;
        background-color: $pink;
        height: 50px;
        width: 250px;
        font-size: 17px;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.2);
        font-weight: bold;
        color: $dark-grey;
        transition: background-color .2s, box-shadow .2s;
        padding: 0;

        &:hover {
          background-color: darken($pink, 10%);
        }

        &:active {
          box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
          background-color: darken($pink, 20%);
        }
        
        a {
          display: inline-block;
          line-height: 50px;
          height: 100%;
          width: 100%;
          color: $dark-grey;
          text-decoration: none;
        }
      }
    }
  }
}

.portfolio-container {
  .section-content {
    background-color: $pink;

    .project-container {
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding-top: 20px;
      padding-bottom: 20px;

      .carousel-root {
        button {
          background-color: transparent;
          box-shadow: none;
          width: auto;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateX(-50%);
          margin-right: -20px;
          &.control-prev {
            &::before {
              border-right: 16px solid $dark-grey;
              margin-left: 20px;
            }
          }
          &.control-next {
            &::before {
              border-left: 16px solid $dark-grey;
            }
          }
          &:hover {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }

      .project-image-container {
        position: relative;
      }

      .project-image-container, p {
        max-width: 85%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
      
      h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      img {
        height: 100%;
        width: 100%;
        display: block;
      }

      .project-image-container:hover .project-image-overlay {
        opacity: 1;
      }

      .project-image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(0, 0, 0, 0.7);

        .link-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 80%;
        }
        a {
          cursor: pointer;
          color: $light-grey;
          font-size: 20px;
          text-align: center;
          transition: text-decoration .2s;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      p {
        text-align: left;
      }

      button {
        cursor: pointer;
        background-color: $yellow;
        height: 50px;
        width: 250px;
        font-size: 17px;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.2);
        font-weight: bold;
        color: $dark-grey;
        transition: background-color .2s, box-shadow .2s;

        &:hover {
          background-color: darken($yellow, 15%);
        }

        &:active {
          box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
          background-color: darken($yellow, 20%);
        }
      }
      
    }
  }
}

.contact-container {
  .section-content {
    background-color: $yellow;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: black;
      text-decoration: none;
      transition: color .2s, border-bottom .2s;
      &:hover {
        color: $dark-grey;
        border-bottom: 1px solid $dark-grey;
      }
    }

    .contact-buttons {
      a {
        &:hover {
          border-bottom: none;
        }
      }
    }

    svg {
      color: black;
      transition: color .25s;
      margin: 20px;
      &:hover {
        color: $dark-grey;
      }
    }
  }
}

.section-title {
  width: 30vw;
  margin: 0px 40px;
  padding: 10px;

}

.section-content {
  width: 80vw;
  height: 100vh;
  background-color: $blue;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding-left: 20px;
  align-items: center;
  justify-items: center;
}

// Media Queries

// Mobile Devices
@media screen and (min-width: 320px) and (max-width: 480px) {
  .mobile-navbar {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    position: fixed;
    right: 0;

    svg {
      color: white;
    }
  }
  .MuiPaper-root {
    background-color:rgba(255, 255, 255, 0.9) !important;
    a {
      width: 100%;
      text-decoration: none;
    }
  }
  .MuiListItemText-root {
    text-align: center;
    color: $pink;
  }
  .splash-container {
    height: 30vh;
    background-position: right 30% center;
    .logo-container {
      width: 90%;
      height: 15vh;
      padding: 10px;
      img {
        margin: 0;
      }
      h1 {
        font-size: 20px;
        margin-top: 5px;
      }
    }
  }
  .section-title {
    width: 80vw;
    margin-bottom: -35px;
  }
  .about-container {
    height: auto;
    flex-direction: column;
    .section-content {
      width: 100vw;
      flex-direction: column;
      padding-top: 50px;
      padding-left: 20px; 
      padding-right: 20px;
      .about-image {
        height: 100px;
        width: 100px;
      }
      p {
        padding: 20px;
      }
      .about-btns button {
        margin-bottom: 30px;
        font-size: 16px;
      }
    }
  }
  .portfolio-container {
    height: 100vh;
    flex-direction: column-reverse;
    margin-top: 60px;
    .section-title {
      margin-bottom: -45px;
    }
    .section-content {
      width: 100vw;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;

      .project-container {
        height: 90%;
        width: 90%;
        padding-top: 20px;

        .carousel-root {
          width: 90%;
          .carousel-slider {
            height: 100%;
          }
          .project-title {
            font-weight: 500;
          }
          .project-image-overlay {
            display: none;
          }
          .mobile-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            button {
              height: 30px;
              width: 125px;
              background-color: $yellow;
              box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.2);
              position: relative;
              top: auto;
              transform: none;
              margin: 10px;
              a {
                color: $dark-grey;
                text-decoration: none;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .contact-container {
    height: 50vh;
    flex-direction: column;
    .section-title {
      margin-bottom: -45px;
    }
    .section-content {
      width: 100vw;
      padding: 5px;
      p {
        padding: 20px;
      }
    }
  }
}

//Tablets
@media (min-width: 481px) and (max-width: 768px) {
  .mobile-navbar {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    position: fixed;
    right: 0;

    svg {
      color: white;
    }
  }
  .MuiPaper-root {
    background-color:rgba(255, 255, 255, 0.9) !important;
    a {
      width: 100%;
      text-decoration: none;
    }
  }
  .MuiListItemText-root {
    text-align: center;
    color: $pink;
  }
  .splash-container {
    height: 50vh;
    background-position: center center;
    .logo-container {
      width: 50%;
      height: 50%;
      h1 {
        font-size: 18px;
      }
    }
  }
  .about-container {
    height: 50vh;
    .section-title {
     margin: 0 20px;
     width: 250px;
    }
    .section-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 0px;
      padding: 0;
      justify-content: center;
      .about-image {
        height: 75px;
        width: 75px;
      }
      p {
        font-size: 16px;
        padding: 15px;
      }
      .about-btns {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        button {
          width: 175px;
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
  .portfolio-container {
    height: 90vh;
    .section-title {
      width: 300px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .section-content {
      height: 100%;
      width: 55vw;
      border-radius: 0px;
      .project-container {
        width: 95%;
        .carousel-root {
          width: 90%;
          .project-title {
            font-weight: 500;
          }
          .project-image-overlay {
            display: none;
          }
          .mobile-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            button {
              height: 30px;
              width: 125px;
              background-color: $yellow;
              box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.2);
              position: relative;
              top: auto;
              transform: none;
              margin: 10px;
              a {
                color: $dark-grey;
                text-decoration: none;
                font-size: 14px;
              }
            }
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
  .contact-container {
    height: 40vh;
    .section-title {
      width: 300px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .section-content {
      border-radius: 0px;
      padding: 0px;
      p {
        padding: 20px;
      }
    }
  }
}

//Small screens, laptops
@media (min-width: 769px) {
  .navbar li {
    margin-left: 0;
    margin-right: 20px;
  }
  .splash-container {
    height: 100vh;
    background-position: center center;
    .logo-container {
      width: 700px;
      height: 50vh;
    }
  }
  .about-container {
    height: 50vh;
    .section-content {
      background-color: $blue;
      height: 100%;
      -webkit-transform: skew(20deg);
      -moz-transform: skew(20deg);
      -o-transform: skew(20deg);
      overflow: hidden;
      margin-right: -150px;
      padding-right: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .about-image {
        height: 150px;
        width: 150px;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        margin-right: -125px;
      }
      p, .about-btns {
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        width: 75%;
        margin: 30px;
      }
      .about-btns {
        width: 600px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -30px;
      }
      
    }
  }
  
  .portfolio-container {
    height: 85vh;
    min-height: 650px;
    .section-content {
      height: 100%;
      -webkit-transform: skew(-20deg);
      -moz-transform: skew(-20deg);
      -o-transform: skew(-20deg);
      margin-left: -200px;
      display: flex;
      justify-content: center;

      .project-container {
        -webkit-transform: skew(20deg);
        -moz-transform: skew(20deg);
        -o-transform: skew(20deg);
        height: 80%;
        width: 60%;

        .carousel-root {
          width: 90%;
          height: 100%;

          .carousel-slider {
            height: 100%;

            .project-title {
              margin-top: 0;
            }
            .mobile-buttons {
              display: none;
            }
          }
        }
      }
    }
  }

  .contact-container {
    height: 50vh;
    .section-content {
      -webkit-transform: skew(20deg);
      -moz-transform: skew(20deg);
      -o-transform: skew(20deg);
      overflow: hidden;
      margin-right: -200px;
      padding-right: 200px;
    }
    p, svg {
      -webkit-transform: skew(-20deg);
      -moz-transform: skew(-20deg);
      -o-transform: skew(-20deg);
    }
  }
}

